import ApiBase from './ApiBase'
import apiUrl from '../config/api'
import {RequestResult} from '@/utils/interface'

//params
interface OrderListParams {
  state: number,
  arrive_time: number,
}


// response

class ApiOrder extends ApiBase {
  protected url: string = apiUrl.hClean;
  protected prefix: string = ''
  protected resultDataKey: string = 'data';

  public orderList(data: OrderListParams): Promise<RequestResult<any>> {
    return this.post('/delivery/order/list', data)
  }

  public orderInfo(data: { order_no: string }): Promise<RequestResult<any>> {
    return this.post('/delivery/order/info', data)
  }

  public confirm(data: { order_no: string }): Promise<RequestResult<boolean>> {
    return this.post('/delivery/order/arrive', data)
  }

  public refund(data: { order_no: string }): Promise<RequestResult<string>> {
    return this.post('/delivery/order/refund', data)
  }
}

export const apiOrder = new ApiOrder()
