









import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  name: "MyFooter",
})

export default class MyFooter extends Vue {
  @Prop({
    type: Number, default: 0
  })

// @ts-ignore
  private active
  private activeIndex = 0;

  handleChange() {
    scrollTo(0,0)
  }

  mounted() {
    this.activeIndex = this.active;
  }
}
