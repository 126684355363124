



































































































import {Component, Vue} from "vue-property-decorator";
import MyFooter from "@/components/MyFooter.vue";
import {apiOrder} from "@API/apiOrder";

@Component({
  name: "OrderInfo",
  components: {MyFooter},
})
export default class Home extends Vue {
  public orderInfo = null as any
  public order_no: string = ''

  created() {
    this.request()
  }

  handleConfirm() {
    this.$dialog.confirm({
      title: '提示',
      message: '确认商品已经送达？',
    }).then(() => {
      const loading = this.$toast.loading({
        message: '加载中...',
        duration: 0,
      })

      apiOrder.confirm({order_no: this.orderInfo.order_no})
          .finally(() => {
            loading.clear()
          })
          .then(() => {
            this.request()
          })
          .catch(e => {
            this.$dialog({
              title: '提交送达失败',
              message: e.message
            })
          })
    })
  }

  handleRefund() {
    this.$dialog.confirm({
      title: '提示',
      message: '确认退款给该客人？',
    }).then(() => {
      const loading = this.$toast.loading({
        message: '加载中...',
        duration: 0,
      })

      apiOrder.refund({order_no: this.orderInfo.order_no})
          .finally(() => {
            loading.clear()
          })
          .then(() => {
            this.request()
          })
          .catch(e => {
            this.$dialog({
              title: '退款失败',
              message: e.message
            })
          })
    })
  }

  request() {
    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
    })

    apiOrder.orderInfo({order_no: this.$route.params.order_no})
        .finally(() => {
          loading.clear()
        })
        .then(res => {
          this.orderInfo = res.data
        })
        .catch(e => {
          this.$dialog({
            title: '获取订单详情失败',
            message: e.message
          })
        })
  }
}
